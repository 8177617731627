import React from "react";

const Hero: React.FC = () => (
  <div className="hero">
    <div className="hero__title">
      <h1 className="text-light">Web and blockchain development</h1>
    </div>
    <div className="cube" />
    <div className="cube" />
    <div className="cube" />
    <div className="cube" />
    <div className="cube" />
    <div className="cube" />
    <div className="cube" />
    <div className="cube" />
    <div className="cube" />
  </div>
);

export default Hero;
