import React from "react";
import Menu from "./Menu";
import Hero from "./Hero";
import Contact from "./Contact";
import Experience from "./Experience";
import Footer from "./Footer";

const App: React.FC = () => {
  return (
    <>
      <Menu />
      <Hero />
      <div className="container pl-5 pr-5">
        <Experience />
        <Contact />
      </div>

      <Footer />
    </>
  );
};

export default App;
