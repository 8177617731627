import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

const Contact: React.FC = () => (
  <div className="custom-mt">
    <a href="mailto:info@synthactic.com">
      <h1 className="text-center text-primary" id="contact">
        <FontAwesomeIcon icon={faEnvelope} className="mr-2" /> GET IN TOUCH
      </h1>
    </a>
  </div>
);

export default Contact;
