import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faDotCircle } from "@fortawesome/free-solid-svg-icons";
import ExperienceRow from "./ExperienceRow";

const languages = [
  {
    img: require("../assets/ts.png"),
    title: "TypeScript",
    url: "https://www.typescriptlang.org/",
    skill: 0,
  },
  {
    img: require("../assets/js.png"),
    title: "JavaScript",
    url: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
    skill: 0,
  },
  {
    img: require("../assets/html5.png"),
    title: "HTML5",
    url: "https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5",
    skill: 0,
  },
  {
    img: require("../assets/css3.png"),
    title: "CSS3",
    url: "https://developer.mozilla.org/en-US/docs/Glossary/CSS",
    skill: 0,
  },
  {
    img: require("../assets/bash.png"),
    title: "Bash",
    url: "https://www.gnu.org/software/bash/",
    skill: 0,
  },
  {
    img: require("../assets/python.png"),
    title: "Python",
    url: "https://www.python.org/",
    skill: 1,
  },
];

const frontend = [
  {
    img: require("../assets/react.png"),
    title: "React",
    url: "https://reactjs.org/",
    skill: 0,
  },
  {
    img: require("../assets/vue.png"),
    title: "Vue.js",
    url: "https://vuejs.org/",
    skill: 1,
  },

  {
    img: require("../assets/redux.png"),
    title: "React Redux",
    url: "https://redux.js.org/",
    skill: 1,
  },
  {
    img: require("../assets/rxjs.png"),
    title: "RxJs",
    url: "https://github.com/ReactiveX/rxjs",
    skill: 1,
  },
];

const styles = [
  {
    img: require("../assets/antd.png"),
    title: "Ant Design",
    url: "https://ant.design/",
    skill: 0,
  },
  {
    img: require("../assets/bootstrap.png"),
    title: "Bootstrap",
    url: "https://getbootstrap.com/",
    skill: 0,
  },
  {
    img: require("../assets/sass.png"),
    title: "Sass",
    url: "https://sass-lang.com/",
    skill: 1,
  },
  {
    img: require("../assets/tailwind.png"),
    title: "Tailwind CSS",
    url: "https://tailwindcss.com/",
    skill: 1,
  },
];

const backend = [
  {
    img: require("../assets/node.png"),
    title: "Node.js",
    url: "https://nodejs.org/en/",
    skill: 0,
  },
  {
    img: require("../assets/express.png"),
    title: "Express",
    url: "https://expressjs.com/",
    skill: 0,
  },
  {
    img: require("../assets/mongodb.png"),
    title: "Mongo DB",
    url: "https://www.mongodb.com/",
    skill: 1,
  },
  {
    img: require("../assets/psql.png"),
    title: "PostgreSQL",
    url: "https://www.postgresql.org/",
    skill: 1,
  },
  {
    img: require("../assets/firebase.png"),
    title: "Firebase",
    url: "https://firebase.google.com/",
    skill: 1,
  },
];

const testing = [
  {
    img: require("../assets/jest.png"),
    title: "Jest",
    url: "https://jestjs.io/",
    skill: 1,
  },
  {
    img: require("../assets/cypress.png"),
    title: "Cypress",
    url: "https://www.cypress.io/",
    skill: 1,
  },
];

const version = [
  {
    img: require("../assets/git.png"),
    title: "Git",
    url: "https://git-scm.com/",
    skill: 0,
  },
  {
    img: require("../assets/github.png"),
    title: "GitHub",
    url: "https://github.com/",
    skill: 0,
  },
  {
    img: require("../assets/bitbucket.png"),
    title: "Bitbucket",
    url: "https://bitbucket.org/",
    skill: 1,
  },
];

const Experience: React.FC = () => (
  <div className="custom-mt">
    <h1 className="text-dark2" id="experience">
      EXPERIENCE
    </h1>
    <hr className="w-100" />
    <p className="mb-5">
      <small>
        <span className="text-success">
          <FontAwesomeIcon icon={faCheckCircle} /> Experienced{" "}
        </span>

        <span className="text-warning ml-3">
          <FontAwesomeIcon icon={faDotCircle} /> Familiar with
        </span>
      </small>
    </p>

    <div className="row justify-content-center">
      <ExperienceRow header="Languages" list={languages} />
      <ExperienceRow header="Frontend" list={frontend} />
      <ExperienceRow header="Styles" list={styles} />
      <ExperienceRow header="Backend" list={backend} />
      <ExperienceRow header="Testing" list={testing} />
      <ExperienceRow header="Version Control" list={version} />
    </div>
  </div>
);

export default Experience;
