import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleUp } from "@fortawesome/free-solid-svg-icons";

const Footer: React.FC = () => (
  <div className="custom-mt">
    <footer className="border-top text-center">
      <small className="d-block my-5 text-muted">
        &copy; Synthactic |{" "}
        <a href="#top">
          Back to top <FontAwesomeIcon icon={faAngleDoubleUp} />
        </a>
      </small>
    </footer>
  </div>
);

export default Footer;
