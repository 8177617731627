import React from "react";

const Menu: React.FC = () => (
  <nav className="navbar navbar-expand-sm navbar-light bg-white" id="top">
    <div className="container">
      <h3 className="my-0 mr-md-auto text-dark2">
        .synthactic<span className="blink">|</span>
      </h3>

      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarcollapse"
        aria-controls="navbarcollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>

      <div className="collapse navbar-collapse" id="navbarcollapse">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <a className="nav-link" href="#experience">
              Experience
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#contact">
              Contact
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
);

export default Menu;
