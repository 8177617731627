import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faDotCircle } from "@fortawesome/free-solid-svg-icons";

const icons = [
  <FontAwesomeIcon
    icon={faCheckCircle}
    className="overlay text-success mr-0 mt-0 mr-md-2 mt-md-2"
  />,
  <FontAwesomeIcon icon={faDotCircle} className="overlay text-warning mr-0 mt-0 mr-md-2 mt-md-2" />,
];

interface IProps {
  header: string;
  list: {
    img: any;
    title: string;
    url: string;
    skill: number;
  }[];
}

const ExperienceRow = ({ header, list }: IProps) => (
  <div>
    <h3>{header}</h3>
    <div className="row mb-5">
      {list.map((skill, zIndex) => (
        <div className="col-3 col-md-2 skill pointer p-2 p-md-3" key={"card-" + skill.title}>
          <a href={skill.url} target="_blank" rel="noopener noreferrer">
            <div className="shadow skill-box" style={{ zIndex }}>
              {icons[skill.skill]}
              <img
                src={skill.img}
                className="img-fluid p-2 p-md-3"
                title={skill.title}
                alt={skill.title}
              />
            </div>
          </a>
        </div>
      ))}
    </div>
  </div>
);

export default ExperienceRow;
